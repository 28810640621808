





import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/form/fields/abstract-field'

@Component
export default class ButtonField extends AbstractField {
  changeValue(): void {
    this.value = ! this.value
  }
}
